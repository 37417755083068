import { format, subMonths } from 'date-fns';
import { isAfter, subDays } from 'date-fns';
import * as z from 'zod';
import { EMPTY_COACH_AVATAR_SRC } from 'constants/user';
import {
  CoachAvailabilityStatusesEnum,
  DayOfWeekEnum,
  GenderEnum,
  GetCoachBookingPageQuery,
  GetCoachCityRankQuery,
  GetLessonRevenueByCoachIdQuery,
  GetUniqueCoachVenueCourtsQuery,
  GetWeeklyLessonsBookedQuery,
  LessonAgeGroupsEnum,
  LessonTypesEnum,
  SkillLevelValuesEnum,
  VenueAccessTypesEnum,
  VenueNetsEnum,
} from 'types/generated/client';
import { FormattedLessonRevenueData, OwnerRank, Player } from 'screens/CoachHome/types';
import { convertUnitPriceToFormattedPrice } from './money/convertUnitPriceToFormattedPrice';

const generateHourOptions = (intervalMinutes: number): HourOption[] => {
  const options = [];
  const totalMinutesInDay = 1440; // 24 hours * 60 minutes

  for (
    let minutesAfterMidnight = 0;
    minutesAfterMidnight < totalMinutesInDay;
    minutesAfterMidnight += intervalMinutes
  ) {
    // Skip the 12:00 PM slot
    if (minutesAfterMidnight === 720) {
      continue;
    }

    const hours = Math.floor(minutesAfterMidnight / 60);
    const minutes = minutesAfterMidnight % 60;
    const display12 = `${((hours + 11) % 12) + 1}:${minutes.toString().padStart(2, '0')} ${
      hours >= 12 ? 'PM' : 'AM'
    }`;
    const display24 = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

    options.push({
      display12,
      display24,
      minutesAfterMidnight,
      id: minutesAfterMidnight,
    });
  }

  return options;
};

export const hourOptions = generateHourOptions(15); // time interval

export const getTimeForMinutes = (minutes: number) => {
  const time = hourOptions.find((option) => option.minutesAfterMidnight === minutes);
  return time?.display24 || '00:00';
};

export const GENDER_OPTIONS = [
  { value: GenderEnum.Male, label: 'Male' },
  { value: GenderEnum.Female, label: 'Female' },
];

export const RATING_SYSTEM = [
  {
    value: 'Self Rating',
    label: 'Self Rating',
  },
];

export const RATING_SYSTEM_TYPE = [
  {
    value: 'Beginner',
    label: 'Beginner',
  },
];

export const MINIMUM_TIME_FROM_START_MINUTES = 60;

export const DEFAULT_START_MINUTES = 420;

export const DEFAULT_END_MINUTES = 1140;

export const MAX_END_TIME_MINUTES = 1365;

export const DEFAULT_COACH_AVAILABILITY = [
  {
    dayOfWeek: DayOfWeekEnum.Sunday,
    checked: true,
    timeSlots: [
      { startTimeMinutes: DEFAULT_START_MINUTES, endTimeMinutes: DEFAULT_END_MINUTES, venueId: '' },
    ],
  },
  {
    dayOfWeek: DayOfWeekEnum.Monday,
    checked: true,
    timeSlots: [
      { startTimeMinutes: DEFAULT_START_MINUTES, endTimeMinutes: DEFAULT_END_MINUTES, venueId: '' },
    ],
  },
  {
    dayOfWeek: DayOfWeekEnum.Tuesday,
    checked: true,
    timeSlots: [
      { startTimeMinutes: DEFAULT_START_MINUTES, endTimeMinutes: DEFAULT_END_MINUTES, venueId: '' },
    ],
  },
  {
    dayOfWeek: DayOfWeekEnum.Wednesday,
    checked: true,
    timeSlots: [
      { startTimeMinutes: DEFAULT_START_MINUTES, endTimeMinutes: DEFAULT_END_MINUTES, venueId: '' },
    ],
  },
  {
    dayOfWeek: DayOfWeekEnum.Thursday,
    checked: true,
    timeSlots: [
      { startTimeMinutes: DEFAULT_START_MINUTES, endTimeMinutes: DEFAULT_END_MINUTES, venueId: '' },
    ],
  },
  {
    dayOfWeek: DayOfWeekEnum.Friday,
    checked: true,
    timeSlots: [
      { startTimeMinutes: DEFAULT_START_MINUTES, endTimeMinutes: DEFAULT_END_MINUTES, venueId: '' },
    ],
  },
  {
    dayOfWeek: DayOfWeekEnum.Saturday,
    checked: true,
    timeSlots: [
      { startTimeMinutes: DEFAULT_START_MINUTES, endTimeMinutes: DEFAULT_END_MINUTES, venueId: '' },
    ],
  },
];

export const dayOfWeekMap = {
  [DayOfWeekEnum.Sunday]: 'Sun',
  [DayOfWeekEnum.Monday]: 'Mon',
  [DayOfWeekEnum.Tuesday]: 'Tue',
  [DayOfWeekEnum.Wednesday]: 'Wed',
  [DayOfWeekEnum.Thursday]: 'Thu',
  [DayOfWeekEnum.Friday]: 'Fri',
  [DayOfWeekEnum.Saturday]: 'Sat',
};

export const validatePhotoString = (value: string) => value.length > 0;

export interface TimeSlot {
  startTimeMinutes: number;
  endTimeMinutes: number;
  venueId?: string;
}

export interface DateTimeSlot {
  checked: boolean;
  dayOfWeek: string;
  timeSlots: TimeSlot[];
}

export interface FinalTimeSlot {
  dayOfWeek: string;
  startTimeMinutes: number;
  endTimeMinutes: number;
  venueId?: string | null;
  userCustomCourtId?: string | null;
  status: CoachAvailabilityStatusesEnum;
}
type AnyObject = { [key: string]: any };

export const filterKeys = (obj: AnyObject): AnyObject => {
  return Object.entries(obj).reduce((acc: AnyObject, [key, value]) => {
    if (!key.includes('has')) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

export interface RatingUpdate {
  rating: any;
  pickleballRatingScaleId: string;
  userId: string;
}

export interface LessonAgeGroupUpdate {
  lessonAgeGroup: LessonAgeGroupsEnum;
  userId: string;
}

export interface SkillLevelGroupUpdate {
  skillLevel: SkillLevelValuesEnum;
  userId: string;
}

export type SchemaErrors = {
  [key: string]: {
    message: string;
  };
};

export interface HourOption {
  display12: string;
  display24: string;
  minutesAfterMidnight: number;
  id: number;
}

export const mergeCoachAvailability = (coachAvailabilities: any[]): any[] => {
  const dayOfWeekMap: { [key: string]: any } = {};

  // Initialize dayOfWeekMap with default structure
  DEFAULT_COACH_AVAILABILITY.forEach((defaultCoachAvailability) => {
    dayOfWeekMap[defaultCoachAvailability.dayOfWeek] = {
      dayOfWeek: defaultCoachAvailability.dayOfWeek,
      checked: false,
      timeSlots: [],
    };
  });

  // Merge coachAvailabilities into dayOfWeekMap
  coachAvailabilities?.forEach((coachAvailability) => {
    const day = coachAvailability.dayOfWeek;
    if (!dayOfWeekMap[day]) return; // Skip if dayOfWeek not in DEFAULT_COACH_AVAILABILITY

    dayOfWeekMap[day].checked = coachAvailability.status === CoachAvailabilityStatusesEnum.Active;

    // Push time slot to timeSlots array
    dayOfWeekMap[day].timeSlots.push({
      startTimeMinutes: coachAvailability.startTimeMinutes,
      endTimeMinutes: coachAvailability.endTimeMinutes,
      venueId:
        coachAvailability?.coachAvailabilityCustomCourt?.id ||
        coachAvailability?.coachAvailabilityVenueId?.id ||
        '',
      id: coachAvailability.id,
    });
  });

  // Add default time slots for days without time slots and unchecked
  Object.values(dayOfWeekMap).forEach((dayAvailability) => {
    if (dayAvailability.timeSlots.length === 0 && !dayAvailability.checked) {
      const defaultDay = DEFAULT_COACH_AVAILABILITY.find(
        (defaultAvailability) => defaultAvailability.dayOfWeek === dayAvailability.dayOfWeek,
      );
      if (defaultDay) {
        dayAvailability.timeSlots = defaultDay.timeSlots || [];
      }
    }
  });

  // Convert dayOfWeekMap into array
  const mergedAvailability = Object.values(dayOfWeekMap);

  return mergedAvailability;
};

export const unMergedDTimeSlots = (
  dateTimeSlots: DateTimeSlot[],
  addresses: VenueAddress[],
): any => {
  const reducedData = dateTimeSlots.reduce<FinalTimeSlot[]>((acc, dateTimeSlot) => {
    dateTimeSlot.timeSlots.forEach((timeSlot) => {
      if (!timeSlot.venueId) {
        return;
      }
      const address = addresses.find((address) => address.id === timeSlot.venueId);
      acc.push({
        dayOfWeek: dateTimeSlot.dayOfWeek,
        startTimeMinutes: timeSlot.startTimeMinutes,
        endTimeMinutes: timeSlot.endTimeMinutes,
        venueId: address?.locationType === LocationType.Venue ? timeSlot.venueId : null,
        userCustomCourtId: address?.locationType === LocationType.Custom ? timeSlot.venueId : null,
        status: dateTimeSlot.checked
          ? CoachAvailabilityStatusesEnum.Active
          : CoachAvailabilityStatusesEnum.Inactive,
      });
    });

    return acc;
  }, []);

  return { reducedData };
};

export function addressExists(array: VenueAddress[], address: string): boolean {
  return array.some((item) => item.address === address);
}

export function zodAlwaysRefine<T extends z.ZodTypeAny>(zodType: T) {
  return z.any().superRefine(async (value, ctx) => {
    const res = await zodType.safeParseAsync(value);

    if (res.success === false) {
      for (const issue of res.error.issues) {
        ctx.addIssue(issue);
      }
    }
  }) as unknown as T;
}

export const formatCoachVenueData = (
  coachAvailabilityData: GetUniqueCoachVenueCourtsQuery['coachAvailability'],
): VenueAddress[] => {
  return coachAvailabilityData.map((availability) => {
    const { coachAvailabilityVenueId, coachAvailabilityCustomCourt, status } = availability;

    const isVenue = coachAvailabilityVenueId !== null;
    const isCustomCourt = coachAvailabilityCustomCourt !== null;

    return {
      id: isVenue
        ? coachAvailabilityVenueId?.id
        : isCustomCourt
        ? coachAvailabilityCustomCourt?.id
        : '',
      latitude: isVenue
        ? coachAvailabilityVenueId?.latitude
        : isCustomCourt
        ? coachAvailabilityCustomCourt?.latitude
        : null,
      longitude: isVenue
        ? coachAvailabilityVenueId?.longitude
        : isCustomCourt
        ? coachAvailabilityCustomCourt?.longitude
        : null,
      address: isVenue
        ? coachAvailabilityVenueId?.addressString || ''
        : isCustomCourt
        ? coachAvailabilityCustomCourt?.fullAddress || ''
        : '',
      images: isVenue ? coachAvailabilityVenueId?.images.map((image) => image.url) : null,
      indoorCourtCount: isVenue ? coachAvailabilityVenueId?.indoorCourtCount : null,
      outdoorCourtCount: isVenue ? coachAvailabilityVenueId?.outdoorCourtCount : null,
      title: isVenue ? coachAvailabilityVenueId?.title : null,
      status: status,
      locationType: isVenue ? LocationType.Venue : LocationType.Custom,
      pickleballNets: coachAvailabilityVenueId?.pickleballNets || null,
      accessType: coachAvailabilityVenueId?.accessType || null,
    };
  });
};

export enum LocationType {
  Custom = 'Custom',
  Venue = 'Venue',
}

export type VenueAddress = {
  id: string;
  latitude: number | null;
  longitude: number | null;
  address: string;
  images?: string[] | null;
  indoorCourtCount?: number | null;
  outdoorCourtCount?: number | null;
  title?: string | null;
  status: string;
  locationType: LocationType;
  pickleballNets: VenueNetsEnum | null;
  accessType?: VenueAccessTypesEnum | null;
};

export const formatLessonsRevenueData = (
  data: GetLessonRevenueByCoachIdQuery['lessons'],
  filterUser: string,
): FormattedLessonRevenueData => {
  const playersMap = new Map<string, Player>();
  let totalRevenue = 0;

  const lessonData: { name: string; lesson: number }[] = [];
  const revenueData: { name: string; group: number }[] = [];
  const lessonBarData: { name: string; group: number; private: number }[] = [];
  const revenueBarData: { name: string; group: number; private: number }[] = [];
  let growthRevenue = 0;
  let growthLessons = 0;
  let ordersCount = 0;
  let cityName = '';

  playersMap.set('', {
    id: '',
    name: 'All',
    profileImage: '',
    badge: false,
    years: [],
    totalLessons: 1,
    totalRevenue: 1,
  });

  // Generate last 12 months from Sep to Aug (if current month is Aug)
  const months = Array.from({ length: 12 }, (_, i) =>
    format(subMonths(new Date(), i), 'MMM'),
  ).reverse();

  // Initialize monthly data
  const monthlyLessonsMap = new Map<
    string,
    {
      lessons: number;
      revenue: number;
      groupLessons: number;
      privateLessons: number;
      groupRevenue: number;
      privateRevenue: number;
    }
  >();

  months.forEach((month) => {
    monthlyLessonsMap.set(month, {
      lessons: 0,
      revenue: 0,
      groupRevenue: 0,
      privateRevenue: 0,
      groupLessons: 0,
      privateLessons: 0,
    });
  });

  // Date one week ago
  const oneWeekAgo = subDays(new Date(), 7);
  const newPlayersSet = new Set<string>();

  data?.forEach((lesson) => {
    cityName = lesson?.ownerProfile?.cityName || lesson?.ownerProfile?.activeCity?.name || '';
    lesson?.orders.forEach((order) => {
      ordersCount += 1;
      const { customerProfile, orderTotalUnitAmount, createdAt } = order;
      if (!customerProfile) return;

      const playerId = customerProfile.id;
      const player = playersMap.get(playerId);
      const lessonDate = new Date(createdAt);
      const month = format(lessonDate, 'MMM');

      totalRevenue += orderTotalUnitAmount;

      if (player) {
        player.totalLessons += 1;
        player.totalRevenue += orderTotalUnitAmount;
      } else {
        playersMap.set(playerId, {
          id: playerId,
          name: customerProfile.fullName || '',
          profileImage: customerProfile.profileImageProviderUrl || EMPTY_COACH_AVATAR_SRC,
          badge: false,
          years: [],
          totalLessons: 1,
          totalRevenue: orderTotalUnitAmount,
        });

        // Check if the player is new in the last week
        if (isAfter(lessonDate, oneWeekAgo)) {
          newPlayersSet.add(playerId);
        }
      }

      if (!filterUser || order.customerUserId === filterUser) {
        // Update monthly data
        const monthlyData = monthlyLessonsMap.get(month);
        if (monthlyData) {
          monthlyData.lessons += 1;
          monthlyData.revenue += orderTotalUnitAmount;
          // Categorize as group or private lesson
          if (lesson?.type === LessonTypesEnum.Individual) {
            monthlyData.privateRevenue += orderTotalUnitAmount;
          } else {
            monthlyData.groupRevenue += orderTotalUnitAmount;
          }

          if (lesson?.type === LessonTypesEnum.Individual) {
            monthlyData.privateLessons += 1;
          } else {
            monthlyData.groupLessons += 1;
          }

          // Update the Map
          monthlyLessonsMap.set(month, monthlyData);
        }
      }
    });
  });

  // Convert monthly data to arrays, ensuring all months are included
  monthlyLessonsMap.forEach((value, key) => {
    lessonData.push({ name: key, lesson: value.lessons });
    revenueData.push({
      name: key,
      group: convertUnitPriceToFormattedPrice(value.revenue).priceFormatted,
    });
    revenueBarData.push({
      name: key,
      group: convertUnitPriceToFormattedPrice(value.groupRevenue).priceFormatted,
      private: convertUnitPriceToFormattedPrice(value.privateRevenue).priceFormatted,
    });
    lessonBarData.push({
      name: key,
      group: value.groupLessons,
      private: value.privateLessons,
    });
  });

  const players = Array.from(playersMap.values());

  if (players?.[0]) {
    players[0].totalLessons = ordersCount || 0;
    players[0].totalRevenue = totalRevenue || 0;
  }

  // Calculate growth in lessons
  if (lessonData.length >= 2) {
    const currentMonthLessons = lessonData[lessonData.length - 1].lesson;
    const previousMonthLessons = lessonData[lessonData.length - 2].lesson;

    if (previousMonthLessons !== 0) {
      growthLessons = +(
        (currentMonthLessons / (previousMonthLessons + currentMonthLessons)) *
        100
      ).toFixed(2);
    } else if (previousMonthLessons === 0 && currentMonthLessons > 0) {
      growthLessons = 100;
    }
  }

  // Calculate growth in revenue
  if (revenueData.length >= 2) {
    const currentMonthRevenue = revenueData[revenueData.length - 1].group;
    const previousMonthRevenue = revenueData[revenueData.length - 2].group;

    if (previousMonthRevenue !== 0) {
      growthRevenue = +(
        (currentMonthRevenue / (previousMonthRevenue + currentMonthRevenue)) *
        100
      ).toFixed(2);
    } else if (previousMonthRevenue === 0 && currentMonthRevenue > 0) {
      growthRevenue = 100;
    }
  }

  return {
    players,
    lessons: data,
    totalLessons: ordersCount || 0,
    totalRevenue,
    lessonData,
    revenueData,
    lessonBarData,
    revenueBarData,
    growthRevenue,
    growthLessons,
    cityName,
    newPlayers: Array.from(newPlayersSet).length || 0, // New unique players in the last week
  };
};

export function getOwnerRank(data: GetCoachCityRankQuery['lessons'], ownerId: string): OwnerRank {
  // Create an object to store the total order count for each owner
  const ownerCounts: Record<string, number> = {};

  // Iterate over the lessons to accumulate the order counts for each owner
  data.forEach((lesson) => {
    const owner = lesson.ownerUserId;
    const orderCount = lesson?.ordersAggregate?.aggregate?.count || 0;

    if (ownerCounts[owner]) {
      ownerCounts[owner] += orderCount;
    } else {
      ownerCounts[owner] = orderCount;
    }
  });

  // Convert the ownerCounts object to an array of [ownerId, count] pairs
  const sortedOwners = Object.entries(ownerCounts).sort((a, b) => b[1] - a[1]);

  // Find the rank of the specified ownerId
  const ownerRank = sortedOwners.findIndex(([owner]) => owner === ownerId) + 1;

  // Return the rank and the total order count for the specified owner
  if (ownerRank > 0) {
    return {
      rank: ownerRank,
      ownerId: ownerId,
      count: ownerCounts[ownerId],
    };
  } else {
    return {
      rank: ownerRank,
      ownerId: ownerId,
      count: ownerCounts[ownerId],
    };
  }
}

export function sumLessonOrders(data: GetWeeklyLessonsBookedQuery) {
  // Extract the lessons array from the data object
  const lessons = data.lessons;

  // Sum up all the counts from ordersAggregate
  const totalCount = lessons.reduce((sum, lesson) => {
    return sum + (lesson?.ordersAggregate?.aggregate?.count || 0);
  }, 0);

  return totalCount;
}
